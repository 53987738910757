// Header height
$header-height-sm: 64px;
$header-height: 80px;

// Colors
$primary: #0C6537;
$secondary: #5DA631;
$dark: #1b1b1b;
$golden: #CFB26D;

// Buttons
$btn-font-weight: 600;

$btn-font-size: 1.125rem;
$btn-border-radius: 0;

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: 0.6875rem;
$btn-border-radius-sm: 0;

$btn-font-size-lg: 1.125rem;
$btn-border-radius-lg: 0;

// Fonts
$proxima-nova-font: "Proxima Nova", roboto, sans-serif;
$font-family-base: $proxima-nova-font;
$font-family-default: $proxima-nova-font;
$enable-responsive-font-sizes: true;

$font-weight-normal: normal;
$font-weight-bold: bold;

$headings-font-weight: $font-weight-bold;



@import "~src/stylesheets/fandom_variables";

$display1-size: 3.5rem;

// Headings
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base;