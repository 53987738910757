@include media-breakpoint-up(xl) {
  .w-limiter {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-height: 1080px) {
  .mh-100vh, 
  .mh-100vh-header {
    height: 1080px !important;
    min-height: auto !important;
  }
}

.text-style-1 {
  font-size: 56px;
  font-style: normal;
  line-height: 1.14;
  font-weight: bold;
  letter-spacing: -0.08px;
}

.text-gold {
  color: $golden !important;
}

strong {
  font-weight: bold !important;
}

p {
  font-size: $font-size-base * 1.25;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.btn {
  &-primary {
    &:hover {
      background-color: $white !important;
      color: $primary !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .show-test {
    height: -webkit-fill-available;
    background-color: $white !important;
  }
}