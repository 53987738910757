
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-component] {
  min-height: $header-height-sm;
  transition: background $animation-time-fast linear;

  &.bg-gradients {
    background: $primary;
  }

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  .navbar-brand {
    min-height: calc(#{$header-height-sm} - 1rem);
  }

  .navbar-logo {
    height: 2.75rem;

    @include media-breakpoint-up(md) {
      height: 4rem;
    }
  }

  .sidebar-toggler, .close-icon {
    transition: color $animation-time-fast;
    
    &:hover {
      opacity: 0.75;
    }
  }
}

[navbar-component] .nav-item,
[sidebar] .nav-item {
  .nav-item-url {
    color: $white;
    transition: color $animation-time-fast;
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    .nav-item-url {
      opacity: 0.75;
    }
  }
}

[sidebar-layer] {
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: 1050;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  overflow-y: auto;

  .sidebar-content,
  .sidebar-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .text-normal {
    text-transform: none;
  }

  .border-bottom,
  &.border-right {
    border-color: rgba($gray-300, 0.15) !important;
  }
}
