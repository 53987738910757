
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  transition: all .25s;

  video {
    object-fit: cover;
  }

  &:not([href]):hover {
    color: var(--theme-color);
  }

  .tile {
    &__icon {
      width: 4rem;
    }

    &__text-section > * {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__lock-icon {
      right: 0;
      width: 7rem;
      bottom: 2rem;
      background-color: $primary;
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }
}
