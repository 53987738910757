
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-carousel-interaction-component] {
  position: fixed;
  background-size: 100% auto;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1031;

  .carousel-header {
    background-color: $white;
    width: 100vw;
    height: 90px;
    
    .logo {
      height: 30px;
    }

    .progress {
      height: 6px;
      background-color: #67A787;
      width: 40%;
    }

    .close-icon {
      z-index: 1;
    }
  }

  .carousel-body {
    height: calc(100% - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .flickity {
    &-viewport, &-carousel {
      &:focus {
        outline: none !important;
      }
    }
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: calc(100vh - 90px);

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

  }

  [two-col-component] {
    .text-column {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  [stripe-text-component] {
    align-items: center;
    display: flex;

    [title], [description] {
      text-align: left;
    }
    
    .bg-cover-center {
      border-radius: 24px;
    }
  }
}
