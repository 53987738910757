
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[console] {
  .main-content-container {
    aspect-ratio: 9 / 16;
    width: 100%;
  }

  .hint-label, .idle-label {
    top: 50px;
    position: absolute; 
    display: flex; 
    justify-content: center; 
    width: 100%;

    &__text {
      font-size: 2vh;
      font-weight: 600;
      width: 60vw;
    }
  }

  .disclaimer-label {
    bottom: 0;
    position: absolute; 
    display: flex; 
    justify-content: center; 
    width: 100%;
    
    &__text {
      font-size: 1vh;
      font-weight: 300;
      opacity: .75;
    }
  }

  .mh-ask-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .mh-ask-label, .user-speech, .waiting-video {
    .transcription {
      width: 80%;
      max-height: 14vh;
      overflow: hidden;
      display: flex;
      flex-direction: column-reverse;

      &::before {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        content: "";
      }

      p {
        font-size: 3.5vh;
        line-height: 1.3;
        font-weight: 600;
        width: 80%;
        text-shadow: 4px 2px 5px #444444;
        margin-bottom: 0;
      }
    }
  }

  .waiting-video {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #000000;

    .embed-responsive {
      position: absolute;
      top: 0;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .bubble-loading-container {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      top: 10vh;
      z-index: 9;
      width: 100%;

      p {
        font-size: 2vh;
        font-weight: 600;
        width: 60vw;
        margin-bottom: 2.5vh;
      }

      .bubble-loading_content {
        width: 100%;
        border-radius: 20px;
        display: inline-block;
        position: relative;
        color: $white;
        font-size: 1.5vh;

        .fa-circle {
          --fa-fade-opacity: 0;
        }
      }
    }
  }

  .user-speech {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    ::v-deep [ai-speech] {
      canvas {
        width: 70vw !important;
        height: 14vh;
        max-width: 100%;
      }

      .icon-container {
        width: 6vh;
        height: 6vh;
        position: absolute;
        border: 1px solid $primary;
        margin-left: 0 !important;
      }

      .label {
        position: absolute;
        font-size: 2vh;
        bottom: 0;
      }

      .fa-microphone {
        font-size: 3vh;
        line-height: .04167em;
        vertical-align: -0.125em;
      }
    }
  }

  .transcription {
    &.blink-animation {
      .transcription__text::after {
        animation: blink 1s steps(5,start) infinite;
        content: "_";
        margin-left: 0.25rem;
        vertical-align: baseline;
      }
    }
  }
}
