
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-ivs-component] {
  position: relative;

  ::v-deep i {
    font-size: 1.5rem;
  }
}
