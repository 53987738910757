
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-bg-video-component] {
  position: relative;

  .row {
    .text-container {
      z-index: 1;
    }

    .video-container {
      position: absolute;
      right: 0;
      bottom: 0px;
    }
  }
}


@include media-breakpoint-down(md) {
  /* Forced no vertical padding on mobile */
  [stripe-bg-video-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .row {
      .video-container {
        position: relative;
      }
    }
  }
}

