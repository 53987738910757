
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-meeting-settings-modal-component] {
  #localVideo {
    max-width: 100%;
    height: auto;
  }

  #oscilloscope {
    width: 100%;
    height: 3rem;
  }
}
