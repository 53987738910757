
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[grid-carousel-component] {
  position: relative;

  .flickity-page-dots {
    display: none;
  }

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 75%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 {
    .flickity-prev-next-button.next {
      right: -4rem;
    }

    .flickity-prev-next-button.previous {
      left: -4rem;
    }

    .flickity-carousel-item {
      width: calc(100% / 3);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  /* side shadows */
  // .flickity-viewport::after,
  // .flickity-viewport::before {
  //   z-index: 1;
  //   opacity: 0;
  //   content: '';
  //   height: 100%;
  //   width: 5.25rem;
  //   position: absolute;
  //   pointer-events: none;
  //   transition: opacity .5s;
  //   background: transparent;
  // }

  // .flickity-carousel .flickity-viewport::after,
  // .flickity-carousel:not(.hide-left-shadow) .flickity-viewport::before {
  //   opacity: 1;
  // }

  // .flickity-carousel .flickity-viewport::before {
  //   background: linear-gradient(to left, rgba(0, 0, 0, 0), $dark);
  // }

  // .flickity-carousel .flickity-viewport::after {
  //   right: 0;
  //   background: linear-gradient(to right, rgba(0, 0, 0, 0), $dark);
  // }
  /* end side shadows */

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 40%;
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      width: 90%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 65%;
    }

    .stripe-carousel__skin-3 .flickity-page-dots {
      display: block;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 100%;
      padding-left: 1rem;
    }
  }
}
