
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-secondary-component] {
  .dark-theme {
    background-color: $dark;

    .nav-link {
      color: rgba($white, .5) !important;
    } 
  }

  &.sticky {
    position: sticky;
    z-index: 1;
  }
}
