
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[comments-component] {
  .list-enter-active, .list-leave-active {
    transition: all .5s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
  .list-move {
    transition: transform .5s;
  }

  .list--no-animate {
    .comment-container {
      transition: none !important;
    }
  }

  &.theme-live {
    --scrollbar-width: 0px;
    --mask-height: 1rem;
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    --mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
    height: 100%;

    .comments-list {
      // https://pqina.nl/blog/fade-out-overflow-using-css-mask-image/
      overflow-y: scroll;
      flex-wrap: nowrap;
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
      mask-position: 0 0, 100% 0;
      mask-repeat: no-repeat, no-repeat;
      display: flex;
      flex-direction: column-reverse;
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      &::-webkit-scrollbar { display: none; }

      @include media-breakpoint-down(md) {
        height: 30vh;
      }

      .comment-container {
        flex: none;

        &:last-child {
          margin-top: 1rem;
        }
      }
    }
  }

  &.themed-light {
    [comment-add-component] {
      .new-comment-area {
        border: 1px solid !important;
      }
    }

    [comment] {
      .comment-preview {
        &__name, &__text {
          text-shadow: none;
        }
      }
    }
  }

  &.themed-dark {
    .new-comment-area {
      border: 0 !important;
    }
  }
}
