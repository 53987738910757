
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[test-interaction-component] {
  .loading-container {
    .image {
      width: 80%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .more-info-box {
    background-color: #f3f8f5;
  }

  ::v-deep [two-col-component] {
    .bg-cover-center {
      @include media-breakpoint-between(sm, md) {
        align-items: center;

        .media-column {
          max-width: 60%;
        }
      }

      .media-column {
        @include media-breakpoint-up(lg) {
          padding-top: 5rem !important;
        }
      }
    }
      
    .media-column {
      align-items: start !important;
    }

    @include media-breakpoint-down(sm) {
      [description] p, [description] li span, .category {
        font-size: 1rem !important;
      }
    }
  }
}
