
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-share-component] {
  .provider-icon {
    border: 1px solid $primary;
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
    i { line-height: 4rem };
  }

}
