
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.header {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11;

  .tag {
    border-radius: $player-border-radius-sm;
    display: flex;
    font-weight: 700;
    max-height: 24px;
    padding: 6px 8px;

    span {
      font-size: 12px;
      line-height: 13px;
    }
  }

  .live-header {
    min-height: 24px;

    .tag-live {
      background: $player-red-color;
      color: $white;
      text-transform: uppercase;

      i {
        -webkit-text-stroke: 1px $white;
        color: transparent;
      }

      &.replay {
        background: $white;
        color: $player-red-color;

        i {
          -webkit-text-stroke: 1px $player-red-color;
        }
      }
    }

    .close-icon {
      font-weight: 400 !important;
      font-size: 24px;
      opacity: .9;
      line-height: 1;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }
  }

  .unmute-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 200px;

    .tag-unmute {
      cursor: pointer;
      background: $white;
      color: #10111D;
    }
  }
}
