
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[ie-banner-component] {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
