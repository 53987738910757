
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.cookie-banner {
  background-color: rgba($white, .9);
  z-index: 1090;
  bottom: 0%;
}

.banner-text {
  @include font-size(.75rem);
}
