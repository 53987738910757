
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[footer-component] {
  background-color: $primary;

  $social-icon-size: 1.25rem;

  .social-icons-wrapper {
    @include media-breakpoint-up(lg) {
      justify-content: end;
    }

    @include ie {
      display: flex;
      justify-content: flex-end;
    }
  }

  .social-icon {
    font-size: $social-icon-size;
    line-height: 1;
    color: $white;

    @include ie {
      margin: 1rem 1.5rem;
    }
  }

  .footer-logo {
    width: 6.5rem;
    height: 6.5rem;
  }

  .navbar-link {
    color: $white;
    font-weight: 900;
    font-size: 1.5rem;
  }

  .navbar-link {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .navbar-link {
      font-size: 0.875rem;
    }

    .footer-logo {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
}
