
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-trivia-component] {
  .image-column {
    max-width: 570px;
  }

  .answer {
    cursor: pointer;
    position: relative;
    border: 1px solid $white;

    &:hover:not(.not-choose) {
      border: 1px solid $primary;
    }

    &.choose {
      border: 1px solid $primary;
      background-color: $primary;
      color: $white !important;

      .custom-control {
        &-input[disabled], &-input:disabled {
          ~ .custom-control-label {
            color: $white !important;
         
            &::before {
              border-color: $white;
              background-color: $primary !important; 
            }
          }
        }
      }
    }

    .custom-control {
      padding-left: 2rem;

      &-input[disabled], &-input:disabled {
        ~ .custom-control-label {
          color: $dark !important;
        }
      }

      &-label {
        cursor: pointer;

        &::before {
          border-color: $primary;
          background-color: $white !important; 
        }

        &::before, &::after {
          left: -2rem;
          width: 2rem;
          height: 2rem;
          top: calc(50% - 1rem);
        }
      }
    }
  }
}
