
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.media {
  cursor: pointer;

  img {
    max-width: 200px;
  }

  .layer {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-title {
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
  }

  &-description {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }
}

@include media-breakpoint-down(sm) {
  .media {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }

    &-img-container {
      margin-right: 0;
    }

    &-body {
      margin-top: 1rem;
    }
  }
}
