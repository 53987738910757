
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.close {
  top: .5rem;
  right: .5rem;

  > i {
    font-size: 1.25rem;
  }
}

.share-icon {
  border-radius: 100%;
  width: 2rem;
  height: 2rem;

  .fa-lg {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    width: 3rem;
    height: 3rem;

    .fa-lg {
      font-size: 1.5rem;
    }
  }
}
