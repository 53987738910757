
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.custom-select-outer {
  width: 100%;
}

.progress-bar-text {
  position: absolute;
  left: 0;
  right: 0;
}

.progress {
  height: 2rem;
}

.form-group {
  margin-bottom: 2rem;

  .form-check-input {
    margin-top: 0.4rem;
  }
}

.btn.disabled, .btn:disabled {
  opacity: .65 !important;
}

::v-deep {
  .feedback-error-title {
    font-weight: bold;
  }
}
