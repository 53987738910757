
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-list-component] {
  position: relative;
  background-size: 100% auto;

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 600px;
      max-width: 100%;
    }

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

  }
}
