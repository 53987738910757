
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-article-component] {
  .interaction-det {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.25rem;
    border-radius: 100%;
    z-index: 1090;
  }
}
