:root {
  --video-width: 100%;
  --video-height: 100%;
}

body {
  &.fullscreen {
    padding-top: 0;

    .fixed-top, [footer-component] {
      display: none;
    }
  }
}

.position-absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.position-fixed-center {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.position-relative {
  position: relative;
}

.linear-gradient {
  background-color: rgba(0, 0, 0, 0.25);
}

.linear-gradient-y {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}

a.deco-none {
  color: inherit;
  text-decoration:none;
}

.cursor-pointer {
  cursor: pointer;
}

.video-16by9 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  video,
  iframe {
    height: 56.25vw;
    width: 177.78vh;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-1by1 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  video,
  iframe {
    height: 100vw;
    width: 100vh;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-9by16 {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  &.video-cover {
    @media (min-aspect-ratio: 9/16) {
      --video-height: 177.78vw
    }
  
    @media (max-aspect-ratio: 9/16) {
      --video-width: 56.25vh
    }
  }

  video,
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--video-width);
    height: var(--video-height);
    transform: translate(-50%, -50%);
  }
}

.embed-responsive-9by16 {
  &::before {
    padding-top: percentage(16 / 9); // 177.7777777778%
  }
}

.btn {
  white-space: normal;
}

.themed-item {
  background-color: var(--theme-background) !important;
  color: var(--theme-color) !important;
}

.themed-item-reverse {
  background-color: var(--theme-color) !important;
  color: var(--theme-background) !important;
}

.hover-themed-item {
  @include hover() {
    background-color: var(--theme-background) !important;
    color: var(--theme-color) !important;
  }
}

.hover-themed-item-reverse {
  @include hover() {
    background-color: var(--theme-color) !important;
    color: var(--theme-background) !important;
  }
}

.themed-border {
  border-color: var(--theme-border-color) !important;
}

.fill-svg {
  fill: currentColor;
}

.stroke-svg {
  stroke: currentColor;
}

.btn-eventbrite {
  background-color: #d1410c;
  color: #fff;

  &:hover {
    background-color: lighten(#d1410c, 10%);
    color: #fff;
  }
}

// .grecaptcha-badge { 
//   visibility: hidden;
// }

@media(min-height: $googlebot-seo-height) {
  .video-16by9 {
    video,
    iframe {
      width: $googlebot-seo-height / 2 * 1.78;
    }
  }
  .video-1by1 {
    video,
    iframe {
      width: $googlebot-seo-height / 2;
    }
  }
}

.font-weight-500 {
  font-weight: 500;
}

$extra-buttons: ("facebook": #4267b2);

@each $color, $value in $extra-buttons {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $extra-buttons {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
