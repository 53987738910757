
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[heading-caruosel-component] {
  position: relative;

  .flickity-carousel {
    position: relative;
    color: $white;
    background-color: $black;
  }

  .flickity-page-dots {
    bottom: .5rem;
    .dot {
      background-color: $white;
      border: 1px solid $white;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }

    .flickity-page-dots {
      bottom: 1rem;
    }
  }
}
