
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni_bosco/javascript/src/stylesheets/cinque_stagioni_bosco_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[heading-media-component] {
  position: relative;

  background-color: var(--theme-background);
  color: var(--theme-color);

  video {
    object-fit: cover;
  }
}
